import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ExpertiseItem',
    props: {
        color: {
            type: String,
            default: '',
            required: false,
            validator(value) {
                return ['green', 'blue', 'grey'].includes(value);
            },
        },
    },
});
